import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStateContext } from "../../../../contexts/ContextProvider";
import { useForm } from "react-hook-form";
import { getCCostosUsuario, hideLoader, limpiarInfoMenuEdicion, showLoader } from "../../../../Redux/Actions";
import { axiosPrivate } from "../../../../common/axiosPrivate";
import toast from "react-hot-toast";


const getMenusIds = (menus) => {
  const ids = [];
  menus.forEach((menu) => {
    ids.push(menu.id.toString());
    // if (menu.childrens.length > 0) {
    //   const childrensIds = getMenusIds(menu.childrens);
    //   ids.push(...childrensIds);
    // }
  });
  return ids;
};

const checkMenusState = (menus, menusId) => {
  const checkedMenus = [];
  menus.forEach((menu) => {
    const newMenu = { ...menu };
    if (menusId.includes(menu.id.toString())) {
      newMenu.checked = true;
    } else {
      newMenu.checked = false;
    }
    // if (menu.childrens?.length > 0) {
    //   const childrensMenus = checkMenusState(menu.childrens, menusId);
    //   newMenu.childrens = childrensMenus;
    //   checkedMenus.push(newMenu);
    // } else {
    //   checkedMenus.push(newMenu);
    // }
    checkedMenus.push(newMenu)
  });
  return checkedMenus;
};


export default function ModificarCentroCosto({
  isVisible,
  onClose,
  modalData,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const [menus, setMenus] = useState([]);

  const { refreshTable, setRefreshTable, screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;

  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const dispatch = useDispatch();

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        getCCostoUsuario(modalData.id),
        dispatch(getCCostosUsuario(modalData.id)),
      ]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [dispatch]);

  const listadoCCostos = useSelector(
    (state) => state.ccostoUsuarios
  );

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("ccostoUsuarios"));
    };
  }, []);


  async function getCCostoUsuario(usuarioId) {
    try {
      toast.loading("Cargando", estilosAlerta);

      const info = await axiosPrivate(
        `${api_service}/api/v1/usuarios/${usuarioId}/centros-costos`,
        Authorization
      );


      if (info.status === 200 && info.data.length > 0) {
        const userMenus = getMenusIds(info.data);
        const newMenus = checkMenusState(menus, userMenus);
        setMenus(newMenus);
        setValue("ccostosUsuario", userMenus);
      } else {
        const userMenus = [];
        const newMenus = checkMenusState(menus, userMenus);
        setMenus(newMenus);
        setValue("ccostosUsuario", userMenus);
      }
      toast.dismiss();
      toast.success("Listado de Centros de Costo cargado con éxito", estilosAlerta);
    } catch (error) {
      toast.dismiss();
      toast.error("Error al cargar el listado de Centros de Costo para este usuario", estilosAlerta);
      console.log(error);
    }
  }


  async function onSubmit(data){

    try {
      toast.loading("Guardando cambios", estilosAlerta);
      const payload = {
        usuarioId: modalData.id,
        centroCostoIds: data.ccostosUsuario,
      };
      const response = await axiosPrivate.post(
        `${api_service}/api/v1/usuarios/centros-costos`,
        payload,
        Authorization
      );
      if (response.status === 200) {
        toast.dismiss();
        toast.success("Usuario editado correctamente", estilosAlerta);
        setRefreshTable(!refreshTable);
        onClose();
        reset();
        // setHabilitado(true);
      }
      // setBotonGuardarHabilitado(true)
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(error.response.data.message ? error.response.data.message : "Error al editar Usuario", estilosAlerta);
      // setBotonGuardarHabilitado(true)
    }

  }

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="primerDivModal">
          <div className="segundoDivModal max-w-lg">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
              {/*header*/}
              <div className="divHeaderModal border-slate-200">
                <h3 className="titulo-Modal">Centros de Costo</h3>
              </div>
              <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">

                <div className="div-Inputs-EditarHaber">
                  <label
                    className="block uppercase dark:text-white tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                    htmlFor="grid-last-name"
                  >
                    Seleccionar Centros de costo a los cuales el usuario tendrá acceso:
                  </label>
                  <div className="border-l-2 border-t-2 border-r-2 border-b-2 h-auto h-max-48 overflow-y-auto px-3">
                    {listadoCCostos.map((ccosto) => (
                      <div key={ccosto.id} className="my-1 border-b-2">
                        <input
                          type="checkbox"
                          {...register("ccostosUsuario", {
                            validate: (value) =>
                              value.length > 0 ||
                              "Debe seleccionar al menos un Centro de Costo",
                          })}
                          value={ccosto.id}
                        />
                        <label className="pl-3 dark:text-white text-hercules-light-font-secondary">
                          {ccosto.nombre}
                        </label>
                      </div>
                    ))}
                  </div>
                  <span
                    className={`text-red-500 dark:text-red-400 text-xs italic ${
                      !errors.ccostosUsuario && "opacity-0 invisible"
                    }`}
                  >
                    {errors.ccostosUsuario
                      ? errors.ccostosUsuario.message
                      : "hidden"}
                  </span>
                </div>

              </div>
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    reset();
                    onClose();
                  }}
                >
                  Cerrar
                </button>
                <button
                  className="bg-hercules-light-primary-button hover:shadow-lg hover:scale-105  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </form>
    </>
  );
}
