import React, { useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { ColumnFilter } from "../../components";
import { toast } from "react-hot-toast";
import UtilsFunctions from "../../utils/UtilsFunctions";
import axios from "axios";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../common/axiosPrivate";
import SimpleTable from "../../components/SimpleTable";
import { parse } from "papaparse";
import TablaIngresoCargasFamiliaresyCreditosCCAF from "../../components/Movimientos/ImportarCreditosCCAFyCargasFamiliares/TableCreditosCCAFyCargasFamiliares";
import {
  hideLoader,
  limpiarInfoMenuEdicion,
  obtenerRegistrosCargasFamiliaresPeriodo,
  showLoader,
} from "../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import RutColumnFilter from "../../components/RutColumnFilter";
import GuiaImportacionPlantillas from "../../components/Movimientos/GuiaImportacionPlantillas";
import ImagenEjemploCCAF from "../../assets/Ejemplo Plantilla Creditos CCAF.jpg";
import ModalMensajeCreditosCAFFyCargasFamiliares from "../../components/Movimientos/ImportarCreditosCCAFyCargasFamiliares/ModalMensajeCreditosCAFFyCargasFamiliares";
import * as XLSX from "xlsx";
import ModalVerCargasFamiliares from "../../components/Movimientos/ImportarCreditosCCAFyCargasFamiliares/ModalVerCargasFamiliares";

export default function IngresoCargasFamiliares() {
  const {
    register,
    getValues,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      periodoId: "",
      periodoAnyo: "",
    },
  });

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const { activeMenu, screenSize, refreshTable, setRefreshTable } =
    useStateContext();
  const [file, setFile] = useState();
  const [arrayCargas, setArrayCreditos] = useState([]);
  const [errorRecibido, setErrorRecibido] = useState([]);
  const [showModalError, setShowModalError] = useState(false);
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true);
  const [showModalGuiaImportacion, setShowModalGuiaImportacion] =
    useState(false);
  const [infoPeriodos, setInfoPeriodos] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [showModalVerDetalle, setShowModalVerDetalle] = useState(false);

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const periodoId = localStorage.getItem("periodoId");
  const mesLogin = localStorage.getItem("periodoMes");
  const anyoLogin = localStorage.getItem("periodoAnyo");

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const empresaId = localStorage.getItem("empresaId");
  const dispatch = useDispatch();

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const infoTablaCargasFamiliares = useSelector(
    (state) => state.infoRegistrosCargasFamiliaresPeriodo
  );

  async function getPeriodosCargados() {
    const infoMeses = await axiosPrivate(
      `${api_service}/api/v1/periodos/cargados`,
      Authorization
    );
    setInfoPeriodos(infoMeses.data);
  }

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(obtenerRegistrosCargasFamiliaresPeriodo(periodoId, empresaId)),
        getPeriodosCargados(),
      ]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [dispatch, refreshTable]);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("contratosSemCorridaPorEmpresa"));
    };
  }, []);

  const validacionTextInput = {
    required: { value: true, message: "Obligatorio" },
  };

  const anyosData = UtilsFunctions.obtenerAnyosHastaActual();
  const [mesesData, setMesesData] = useState([]);

  const handleYearChange = (e) => {
    const year = parseInt(e);

    // Encontrar el objeto en infoPeriodos que tiene el 'anyo' igual a year.
    const yearData = infoPeriodos.find((item) => item.anyo === year);

    if (yearData) {
      // Filtrar los meses que cumplen con las condiciones y establecerlos en mesesData.
      const filteredMonths = yearData.periodos.filter(
        (mes) => mes.datosPrevisionalesCargados === true
      );
      const transformedMonths = filteredMonths.map((month) =>
        UtilsFunctions.integerToMonth(month.mes)
      );
      setMesesData(transformedMonths);
    } else {
      // Si no se encontró ningún año con el valor seleccionado, establecer mesesData como un arreglo vacío.
      setMesesData([]);
    }
  };

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  function descargarPlantillaCargasFamiliares() {
    try {
      toast.loading("Descargando Plantilla", estilosAlerta);
      axiosPrivate({
        url: `${api_service}/api/v1/haberes/cargas-familiares/masivo/descargar-plantilla`,
        method: "GET",
        //   data: data,
        maxBodyLength: Infinity,
        headers: { Authorization: `Bearer ${tokenUser}`, ClientId: `${token}` },
        responseType: "blob", // importante
        onDownloadProgress: (progressEvent) => {
          Math.round((progressEvent.loaded * 100) / progressEvent.total);
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "PlantillaCargasFamiliares.csv");
        document.body.appendChild(link);
        link.click();
        toast.dismiss();
        toast.success("Plantilla descargada con éxito", estilosAlerta);
      });
    } catch (error) {
      console.log(error);
    }
  }

  const detectDelimiter = (csv) => {
    if (csv.includes(";")) return ";";
    if (csv.includes(",")) return ",";
    throw new Error(
      "No se pudo detectar el delimitador. Verifique el archivo."
    );
  };

  const csvToJson2 = (csv) => {
    const delimiter = detectDelimiter(csv); // Detectar delimitador dinámicamente
    parse(csv, {
      delimiter,
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        try {
          const headersRequeridos = [
            "RUN Beneficiario",
            "Digito Beneficiario",
            "Tramo",
            "Tipo Carga",
            "Monto",
            "Retroactiva",
            "Reintegro",
          ];
          UtilsFunctions.validarColumnasPlantilla(
            result.data,
            headersRequeridos,
            "Plantilla de Ingreso Cargas Familiares"
          );
          setArrayCreditos(result.data); // Actualizar el estado
          guardarCreditosCCAF(result.data); // Enviar los datos directamente
        } catch (error) {
          console.log("error", error);
          toast.dismiss();
          toast.error(error.message);
        }
      },
    });
  };

  const infoValue = getValues();

  const reader = new FileReader();

  function xlsxToJson(event) {
    console.log("reader", reader);
    try {
      console.log("Entro a xlsx");

      // Leer el archivo como ArrayBuffer
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Obtener la primera hoja del archivo
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      // Obtener los datos como un array bidimensional
      const fileData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      console.log("Datos originales del archivo:", fileData);

      // Asegurarnos de que el archivo no esté vacío
      if (fileData.length === 0) {
        throw new Error("El archivo está vacío o no tiene datos.");
      }

      // Extraer la primera fila como encabezados
      const headers = fileData[0];
      console.log("Cabeceras detectadas:", headers);

      // Crear un array de objetos a partir de las cabeceras y los valores
      const formattedData = fileData.slice(1).map((row) => {
        const rowObject = {};
        headers.forEach((header, index) => {
          rowObject[header] = row[index] ?? ""; // Asignar valores o cadena vacía si faltan
        });
        return rowObject;
      });

      console.log("Datos formateados:", formattedData);

      // Validar las cabeceras y guardar los datos
      const headersRequeridos = [
        "RUN Causante",
        "Digito Causante",
        "Tramo",
        "Valor Carga",
        "Tipo de Causante",
        "Monto Retroactivo",
        "Monto Reintegro",
      ];
      UtilsFunctions.validarColumnasPlantilla(
        formattedData,
        headersRequeridos,
        "Plantilla de Ingreso Cargas Familiares Los Andes"
      );

      setArrayCreditos(formattedData); // Actualizar el estado
      guardarCreditosCCAF(formattedData); // Enviar los datos directamente
    } catch (error) {
      console.error("Error reading Excel file:", error);
      toast.dismiss();
      toast.error(error ? error : "Error al leer el archivo");
    }
    reader.onerror = (error) => {
      console.error("FileReader error:", error);
      toast.dismiss();
      toast.error("Error al cargar el archivo");
    };
    reader.readAsArrayBuffer(file);
  }

  async function cargarDatosCargasFamiliares() {
    if (!file) {
      toast.dismiss();
      toast.error("Primero debe subir una plantilla", estilosAlerta);
      return null;
    }

    const tipoPlantilla = watch("tipoPlantilla");
    const extension = file.name.split(".").pop().toLowerCase(); // Obtiene la extensión del archivo en minúsculas

    if (tipoPlantilla === "hercules" && extension !== "csv") {
      toast.dismiss();
      toast.error(
        "Debe subir un archivo con extensión .csv para la plantilla Hércules",
        estilosAlerta
      );
      return null;
    }
    if (tipoPlantilla === "losandes" && !["xls", "xlsx"].includes(extension)) {
      toast.dismiss();
      toast.error(
        "Debe subir un archivo con extensión .xls o .xlsx para la plantilla Los Andes",
        estilosAlerta
      );
      return null;
    }

    let data = new FormData();
    data.append("plantillaCCAF", file);

    const infoValue = getValues();

    try {
      toast.loading("Importando datos", estilosAlerta);
      const infoPeriodo = await axiosPrivate.get(
        `${api_service}/api/v1/periodos/mes/${infoValue.numeroMes}/anyo/${infoValue.periodoAnyo}`,
        Authorization
      );

      setValue("periodoId", infoPeriodo.data.id);

      const reader = new FileReader();

      if (tipoPlantilla === "hercules") {
        reader.onload = function (event) {
          const text = event.target.result;
          console.log("text", text);
          csvToJson2(text);
        };
        reader.readAsText(file);
      }
      if (tipoPlantilla === "losandes") {
        reader.onload = function (event) {
          xlsxToJson(event);
        };
        reader.readAsArrayBuffer(file);
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Error al importar datos", estilosAlerta);
      console.log("error", error);
    }
  }

  async function guardarCreditosCCAF(data) {
    const infoValue = getValues();
    const tipoPlantilla = watch("tipoPlantilla");

    // setBotonGuardarHabilitado(false);
    console.log("Datos a guardar:", data); // Verifica que los datos lleguen correctamente
    const cargasFamiliares = data.map((e) => {
      if (!e["RUN Beneficiario"]) {
        toast.error("Hay filas sin RUT del trabajador", estilosAlerta);
        throw new Error("Hay filas sin RUT del trabajador");
      }
      if (
        !e["RUN Beneficiario"] ||
        (!e["Digito Beneficiario"] && tipoPlantilla === "hercules") ||
        !e["Tramo"] ||
        (!e["Tipo Carga"] && tipoPlantilla === "hercules") ||
        (!e["Tipo de Causante"] && tipoPlantilla === "losandes") ||
        (!e["Monto"] && tipoPlantilla === "hercules") ||
        (!e["Valor Carga"] && tipoPlantilla === "losandes")
      ) {
        toast.error("Hay campos sin completar", estilosAlerta);
        throw new Error("Hay campos sin completar");
      }

      const isHercules = tipoPlantilla === "hercules";
      return {
        runBeneficiario: e["RUN Beneficiario"]
        ? String(e["RUN Beneficiario"]).replaceAll(".", "")
        : "",
        digitoBeneficiario: String(e["Digito Beneficiario"]),
        tramo: e["Tramo"],
        tipoCarga: isHercules
        ? e["Tipo Carga"]
        : e["Tipo de Causante"] === "Corriente" ? "0" : e["Tipo de Causante"] === "Maternal" ? "2" : "1",
        monto: isHercules
          ? e["Monto"].replaceAll(".", "")
          : String(e["Valor Carga"]).replaceAll(".", ""),
        montoRetroactivo: isHercules
          ? e["Retroactiva"].replaceAll(".", "")
          : e["Monto Retroactivo"] === "" ? 0 : String(e["Monto Retroactivo"]).replaceAll(".", ""),
        montoReintegro: isHercules
          ? e["Reintegro"].replaceAll(".", "")
          : e["Monto Reintegro"] === "" ? 0 : String(e["Monto Reintegro"]).replaceAll(".", ""),
      };
    });
    toast.loading("Guardando Cargas Familiares", estilosAlerta);
    try {
      console.log("body", cargasFamiliares);
      const info = await axios.post(
        `${api_service}/api/v1/trabajadores/cargas-familiares/periodo/${infoValue.periodoId}/empresa/${empresaId}`,
        cargasFamiliares,
        Authorization
      );
      if (Array.isArray(info.data) && info.data.length > 0) {
        setErrorRecibido(info.data);
        setShowModalError(true);
        toast.dismiss();
        toast.error(
          "Hay " +
            info.data.length +
            (info.data.length > 1 ? " cargas" : " carga") +
            " con error",
          estilosAlerta
        );
      } else {
        toast.dismiss();
        toast.success("Cargas Familiares cargadas", estilosAlerta);
        setRefreshTable(!refreshTable);
        setArrayCreditos([]);
      }
      // setBotonGuardarHabilitado(true);
    } catch (error) {
      console.log("error", error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Hubo un error al cargar Cargas Familiares",
        estilosAlerta
      );
      // setBotonGuardarHabilitado(true);
    }
  }

  const COLUMNS = [
    {
      Header: "RUT",
      accessor: "rut",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Nombres",
      accessor: (d) =>
        d.nombres + " " + d.apellidoPaterno + " " + d.apellidoMaterno,
      Filter: ColumnFilter,
    },
    {
      Header: "Cargas Totales",
      accessor: "numeroCargasTotales",
      Filter: ColumnFilter,
    },
    {
      Header: "Tramo Asignación Familiar",
      accessor: "tramoAsignacionFamiliar",
      Filter: ColumnFilter,
    },
    {
      Header: "Asignación Familiar Mensual",
      accessor: (d) =>
        "$" + UtilsFunctions.integerFormatter(d.asignacionFamiliarTotalMensual),
      Filter: ColumnFilter,
    },
    {
      Header: "Cargas Invalidez",
      accessor: "numeroCargasInvalidez",
      Filter: ColumnFilter,
    },
    {
      Header: "Cargas Familiar Maternal",
      accessor: (d) => (d.cargaFamiliarMaternal ? "Si" : "No"),
      Filter: ColumnFilter,
    },
    {
      Header: "Total Retroactivo",
      accessor: (d) =>
        "$" + UtilsFunctions.integerFormatter(d.totalRetroactivo),
      Filter: ColumnFilter,
    },
    {
      Header: "Total Reintegro",
      accessor: (d) =>
        "$" + UtilsFunctions.integerFormatter(d.totalReintegro),
      Filter: ColumnFilter,
    }
  ];

  const texto = (
    <>
      <p className="pb-3">
        Esta plantilla tiene las siguientes columnas, por favor verifique que
        los datos ingresados tengan el formato requerido:
      </p>
      <ul>
        <li className="py-2">
          <b>Correlativo: </b>Número entero de hasta 4 dígitos. Rellenar con
          ceros a la izquierda si es necesario.
        </li>
        <li className="py-2">
          <b>RUN Beneficiario: </b>RUN del trabajador sin puntos ni guion ni
          dígito verificador (OBLIGATORIO).
        </li>
        <li className="py-2">
          <b>Digito Beneficiario: </b>Dígito verificador del RUN del trabajador
          (OBLIGATORIO).
        </li>
        <li className="py-2">
          <b>Nombre Beneficiario: </b>Nombre del trabajador.
        </li>
        <li className="py-2">
          <b>Tramo: </b>A, B, C, D, E. (OBLIGATORIO).
        </li>
        <li className="py-2">
          <b>RUN Causante: </b>RUN del causante sin puntos ni guion ni dígito
          verificador.
        </li>
        <li className="py-2">
          <b>Digito Causante: </b>Dígito verificador del RUN del causante.
        </li>
        <li className="py-2">
          <b>Tipo Carga: </b>0, 1 o 2: 0 - Normal, 1-Por incapacidad,
          2-Maternidad (OBLIGATORIO).
        </li>
        <li className="py-2">
          <b>Monto: </b>Número entero (OBLIGATORIO).
        </li>
      </ul>
    </>
  );

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Movimientos</div>
      <div className="titulo-Pagina">Ingreso Cargas Familiares</div>

      <div>
        <p className="mt-3 dark:text-white text-hercules-light-font-secondary">
          Ante cualquier duda o conflicto al importar plantillas, le
          recomendamos revisar{" "}
          <u>
            <button
              onClick={() => setShowModalGuiaImportacion(true)}
              className="hover:cursor-pointer font-medium underline"
            >
              esta guia
            </button>
          </u>
        </p>
      </div>

      <div className="rounded-lg pt-5 ">
        {infoTablaCargasFamiliares.length > 0 ? (
          <div className="h-full rounded-b-lg md:mr-12">
            <TablaIngresoCargasFamiliaresyCreditosCCAF
              col={COLUMNS}
              tableData={infoTablaCargasFamiliares || []}
              tipoTabla="cargasFamiliares"
              primaryButton={{
                type: "ver",
                onClickFunc: (data) => {
                  setShowModalVerDetalle(true);
                  setModalData(data);
                  console.log("data", data);
                },
                active: true,
              }}
            />
          </div>
        ) : (
          <div className="h-608 bg-white dark:bg-hercules-dark-bg-100 border-gray-300 dark:border-dark-bg-200 md:mr-12 rounded-b-md rounded-tr-md border-y border-x">
            <h4 className="text-xl md:mr-20 md:ml-11 md:pt-11 md:pb-20 dark:text-gray-50 text-hercules-light-font-secondary">
              En el periodo {UtilsFunctions.integerToMonth(mesLogin)}/
              {anyoLogin} no hay registros de trabajadores con Cargas
              Familiares, puede descargar la plantilla e importar las Cargas
              Familiares que desee.
            </h4>
          </div>
        )}

        <div className="pt-4 flex flex-wrap">
          <div className="w-2/12 pr-3 mb-3">
            <label className=" dark:text-white text-hercules-light-font-secondary">
              Tipo Plantilla
            </label>
            <select
              {...register("tipoPlantilla", {
                ...validacionTextInput,
              })}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option hidden value="">
                Seleccionar
              </option>
              <option value="hercules">Plantilla de Hércules</option>
              <option value="losandes">Plantilla de Los Andes</option>
            </select>
            <span
              className={`dark:text-red-400 text-red-600 text-xs ${
                !errors.tipoPlantilla && "opacity-0 invisible"
              }`}
            >
              {errors.tipoPlantilla ? errors.tipoPlantilla.message : "hidden"}
            </span>
          </div>

          {watch("tipoPlantilla") === "hercules" && (
            <div className="w-full md:w-fit mt-3 md:mt-6 md:pl-3 mb-3">
              <button
                onClick={() => descargarPlantillaCargasFamiliares()}
                className="hover:scale-105 bg-hercules-dark-primary-button text-white font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
              >
                Descargar plantilla
              </button>
            </div>
          )}
        </div>

        {watch("tipoPlantilla") !== "" && (
          <div className="flex flex-wrap">
            <form onSubmit={handleSubmit(cargarDatosCargasFamiliares)}>
              <div className="flex flex-wrap md:w-full">
                {/* Selector de año y mes */}
                <div className="flex flex-wrap md:w-fit">
                  <div className="w-4/12 md:min-w-[100px] md:max-w-[200px] pr-3 mb-3">
                    <label className=" dark:text-white text-hercules-light-font-secondary">
                      Periodo
                    </label>
                    <select
                      {...register("periodoAnyo", {
                        onChange: (e) => {
                          setValue("periodoMes", "");
                          handleYearChange(e.target.value);
                        },
                        ...validacionTextInput,
                      })}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option hidden value="">
                        Año
                      </option>
                      {anyosData.map((anyo, i) => (
                        <option value={anyo} key={i}>
                          {anyo}
                        </option>
                      ))}
                    </select>
                    <span
                      className={`dark:text-red-400 text-red-600 text-xs ${
                        !errors.periodoAnyo && "opacity-0 invisible"
                      }`}
                    >
                      {errors.periodoAnyo
                        ? errors.periodoAnyo.message
                        : "hidden"}
                    </span>
                  </div>

                  <div className="w-5/12 md:w-6/12 md:min-w-[130px] md:max-w-[200px] mt-6 md:mr-3 mb-3">
                    <select
                      {...register("periodoMes", {
                        onChange: (e) => {
                          setValue("numeroMes", e.target.selectedIndex);
                        },
                        ...validacionTextInput,
                      })}
                      disabled={watch("periodoAnyo") === "" ? true : false}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option hidden value="">
                        Mes
                      </option>
                      {mesesData.map((mes, i) => (
                        <option value={mes} key={i}>
                          {mes}
                        </option>
                      ))}
                    </select>
                    <span
                      className={`dark:text-red-400 text-red-600 text-xs ${
                        !errors.periodoMes && "opacity-0 invisible"
                      }`}
                    >
                      {errors.periodoMes ? errors.periodoMes.message : "hidden"}
                    </span>
                  </div>
                </div>

                {/* Input para seleccionar archivo */}
                <div className="md:w-96 md:pt-5 md:px-3">
                  <input
                    type={"file"}
                    id={"xlsxFileInput"}
                    accept={
                      watch("tipoPlantilla") === "hercules"
                        ? ".csv"
                        : watch("tipoPlantilla") === "losandes"
                        ? ".xlsx,.xls"
                        : "" // Por defecto no aceptará nada
                    }
                    onChange={handleOnChange}
                    required
                    className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  />
                </div>

                {/* Boton para importar */}
                <div className="w-full mt-6 md:mt-5 md:w-2/12 mb-6 md:mb-0">
                  <button
                    type="submit"
                    className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 pt-3 pb-4 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                  >
                    Importar
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>

      {showModalGuiaImportacion && (
        <GuiaImportacionPlantillas
          nombrePlantilla="Cargas Familiares"
          body={texto}
          imagen={ImagenEjemploCCAF}
          isVisible={showModalGuiaImportacion}
          onClose={() => setShowModalGuiaImportacion(false)}
        />
      )}

      {showModalVerDetalle && (
        <ModalVerCargasFamiliares
          isVisible={showModalVerDetalle}
          onClose={() => setShowModalVerDetalle(false)}
          modalData={modalData}
        />
      )}

      {showModalError && (
        <ModalMensajeCreditosCAFFyCargasFamiliares
          nombrePlantilla="Cargas Familiares"
          erroresRecibidos={errorRecibido}
          isVisible={showModalError}
          onClose={() => setShowModalError(false)}
        />
      )}
    </div>
  );
}
