import React, { useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getCargos,
  getCentrosCostosByEmpresaId,
  getDescuentos,
  getHaberes,
  getJornadas,
  getSecciones,
  limpiarInfoMenuEdicion,
} from "../../Redux/Actions";
import ActualizarHaberesDescuentosTable from "../../components/Herramientas/ActualizarHaberesDescuentos/ActualizarHaberesDescuentosTable";
import UtilsFunctions from "../../utils/UtilsFunctions";
import { ColumnFilter } from "../../components";
import { toast } from "react-hot-toast";
import { axiosPrivate } from "../../common/axiosPrivate";
import ModalEditarAnexoContrato from "../../components/Herramientas/ActualizarAnexosContratos/EditarAnexosContratos";
import TablaActualizarAnexosContratos from "../../components/Herramientas/ActualizarAnexosContratos/TablaActualizarAnexosContratos";

export default function ActualizarAnexosContratos() {
  const { register, getValues, setValue, watch, handleSubmit, reset, formState } = useForm({
    defaultValues: {
      nombreHaberDescuento: "",
    },
  });

  const validacionCampoObligatorio = {
    required: { value: true, message: "Obligatorio" },
  };

  const { errors } = formState;
  const dispatch = useDispatch();
  const { activeMenu, refreshTable, setRefreshTable, screenSize } =
    useStateContext();
  const listaHaberes = useSelector((state) => state.getHaberes);
  const infoCCosto = useSelector((state) => state.centrosCostoEmpresa);
  const jornadas = useSelector((state) => state.getJornadas);
  const cargos = useSelector((state) => state.getCargos);
  const secciones = useSelector((state) => state.getSecciones);
  const [infoTabla, setInfoTabla] = useState([]);
  const [filasSeleccionadasObjVacio, setFilasSeleccionadasObjVacio] = useState(
    {}
  );
  const [showModalErrorActualizarHaberes, setShowModalErrorActualizarHaberes] =
    useState(false);

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const empresaId = localStorage.getItem("empresaId");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const validacionTextInput = {
    required: { value: true, message: "Obligatorio" },
  };

  useEffect(() => {
    dispatch(getHaberes());
    dispatch(getCentrosCostosByEmpresaId(empresaId));
    dispatch(getJornadas());
    dispatch(getCargos());
    dispatch(getSecciones());
  }, [refreshTable]);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("getCargos"));
      dispatch(limpiarInfoMenuEdicion("getJornadas"));
      dispatch(limpiarInfoMenuEdicion("centrosCostoEmpresa"));
      dispatch(limpiarInfoMenuEdicion("getSecciones"));
      dispatch(limpiarInfoMenuEdicion("getHaberes"));
      // reset({})
      // setRowDataAnexo(false)
    };
  }, []);

  async function onSubmit(data) {
    toast.loading("Cargando tabla", estilosAlerta);
    try {
      const response = await axiosPrivate(
        `${api_service}/api/v1/contratos/masivos?empresaId=${empresaId}${
          data.centroCostoId !== "0"
            ? "&centroCostoId=" + data.centroCostoId
            : ""
        }${
          data.tipoContratoAnexo === "Anexo"
            ? "&isAnexoContrato=" + true
            : "&isAnexoContrato=" + false
        }${
          data.plazoContratoId !== "0"
            ? "&plazoContratoId=" + data.plazoContratoId
            : ""
        }${
          data.fechaIngresoDesde !== ""
            ? "&fechaIngresoDesde=" + data.fechaIngresoDesde
            : ""
        }${
          data.fechaIngresoHasta !== ""
            ? "&fechaIngresoHasta=" + data.fechaIngresoHasta
            : ""
        }${
          data.fechaTerminoDesde !== ""
            ? "&fechaTerminoDesde=" + data.fechaTerminoDesde
            : ""
        }${
          data.fechaTerminoHasta !== ""
            ? "&fechaTerminoHasta=" + data.fechaTerminoHasta
            : ""
        }${data.seccionId !== "0" ? "&seccionId=" + data.seccionId : ""}${
          data.jornadaId !== "0" ? "&jornadaId=" + data.jornadaId : ""
        }${data.cargoId !== "0" ? "&cargoId=" + data.cargoId : ""}${
          data.haberId !== "0" ? "&haberId=" + data.haberId : ""
        }`,
        Authorization
      );
      if (!response.data.length > 0) {
        toast.dismiss();
        toast.error("No se encontraron registros que coincidan con los filtros seleccionados", estilosAlerta);
        setInfoTabla([]);
      } else {
        setInfoTabla(response.data);
        toast.dismiss();
        toast.success("Tabla cargada con éxito", estilosAlerta);
      }
    } catch (error) {
      console.log("error", error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al cargar tabla",
        estilosAlerta
      );
    }
  }

  const COLUMNS = [
    {
      Header: "RUT",
      accessor: (d) =>
        UtilsFunctions.rutFormatter(d.rutTrabajador, { dots: true }),
      Filter: ColumnFilter,
    },
    {
      Header: "Apellido paterno",
      accessor: "apellidoPaternoTrabajador",
      Filter: ColumnFilter,
    },
    {
      Header: "Apellido materno",
      accessor: "apellidoMaternoTrabajador",
      Filter: ColumnFilter,
    },
    {
      Header: "Nombre",
      accessor: "nombreTrabajador",
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha ingreso",
      accessor: "fechaIngresoLegal",
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha término",
      accessor: (d) =>
        d.fechaTerminoContrato ? d.fechaTerminoContrato : "Indefinido",
      Filter: ColumnFilter,
    },
    {
      Header: "Centro de Costo",
      accessor: "centroCosto",
      Filter: ColumnFilter,
    },
    {
      Header: "Cargo",
      accessor: "cargo",
      Filter: ColumnFilter,
    },
    {
      Header: "Jornada",
      accessor: "jornada",
      Filter: ColumnFilter,
    },
    {
      Header: "Sección",
      accessor: "seccion",
      Filter: ColumnFilter,
    },
  ];

  async function actualizarTabla() {
    try {
      const data = getValues();
      const responseHaberes = await axiosPrivate(
        `${api_service}/api/v1/contratos/masivos?empresaId=${empresaId}${
          data.centroCostoId !== "0"
            ? "&centroCostoId=" + data.centroCostoId
            : ""
        }${
          data.tipoContratoAnexo === "Anexo"
            ? "&isAnexoContrato=" + true
            : false
        }${
          data.plazoContratoId !== "0"
            ? "&plazoContratoId=" + data.plazoContratoId
            : ""
        }${data.seccionId !== "0" ? "&seccionId=" + data.seccionId : ""}${
          data.fechaIngresoDesde !== ""
            ? "&fechaIngresoDesde=" + data.fechaIngresoDesde
            : ""
        }${
          data.fechaIngresoHasta !== ""
            ? "&fechaIngresoHasta=" + data.fechaIngresoHasta
            : ""
        }${
          data.fechaTerminoDesde !== ""
            ? "&fechaTerminoDesde=" + data.fechaTerminoDesde
            : ""
        }${
          data.fechaTerminoHasta !== ""
            ? "&fechaTerminoHasta=" + data.fechaTerminoHasta
            : ""
        }${data.seccionId !== "0" ? "&seccionId=" + data.seccionId : ""}${
          data.jornadaId !== "0" ? "&jornadaId=" + data.jornadaId : ""
        }${data.cargoId !== "0" ? "&cargoId=" + data.cargoId : ""}${
          data.haberId !== "0" ? "&haberId=" + data.haberId : ""
        }`,
        Authorization
      );
      setRefreshTable(!refreshTable);
      setFilasSeleccionadasObjVacio({});
      setInfoTabla(responseHaberes.data);
      toast.dismiss();
      toast.success("Tabla actualizada con éxito", estilosAlerta);
    } catch (error) {
      toast.dismiss();
      toast.error("Error al actualizar la tabla", estilosAlerta);
    }
  }

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Herramientas</div>
      <div className="titulo-Pagina">Actualizar Anexos/Contratos</div>

      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-wrap pt-3 pb-1">
          <div className="w-full md:w-2/12 md:pr-3">
            <label className="dark:text-white text-hercules-light-font-secondary">
              Centro de Costo
            </label>
            <select
              {...register("centroCostoId")}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option value={"0"}>Todos</option>
              {infoCCosto?.map((ccosto, i) => (
                <option value={ccosto.id} key={i}>
                  {ccosto.nombre}
                </option>
              ))}
            </select>
          </div>

          <div className="w-full md:w-2/12 md:mx-3">
            <label className="dark:text-white text-hercules-light-font-secondary">
              Tipo Contrato/Anexo
            </label>
            <select
              {...register("tipoContratoAnexo", validacionTextInput)}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option value="" hidden>
                Seleccionar
              </option>
              <option value="Anexo">Anexo</option>
              <option value="Contrato">Contrato</option>
            </select>
            <span
              className={`text-red-500 dark:text-red-400 text-xs italic ${
                !errors.tipoContratoAnexo && "opacity-0 invisible"
              }`}
            >
              {errors.tipoContratoAnexo
                ? errors.tipoContratoAnexo.message
                : "hidden"}
            </span>
          </div>

          <div className="w-full md:w-2/12 md:mx-3">
            <label className="dark:text-white text-hercules-light-font-secondary">
              Plazo Contrato
            </label>
            <select
              {...register("plazoContratoId")}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option value="0" hidden>
                Todos
              </option>
              <option value="1">Fijo</option>
              <option value="2">Indefinido</option>
            </select>
          </div>
        </div>

        <div className="w-full mt-3 flex flex-wrap mb-9">
          <div className="w-full md:mr-4 md:w-4/12">
            <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
              Fecha Ingreso Legal (opcional)
            </label>
            <div className="w-full mt-2 flex flex-wrap">
              <div className="w-full md:pr-4 md:w-1/2">
                <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                  Desde
                </label>
                <input
                  {...register("fechaIngresoDesde")}
                  className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                  type="date"
                />
              </div>

              <div className="w-full md:pl-4 md:w-1/2">
                <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                  Hasta
                </label>
                <input
                  {...register("fechaIngresoHasta")}
                  className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                  type="date"
                />
              </div>
            </div>
          </div>

          <div className="w-full md:ml-4 md:w-4/12">
            <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
              Fecha Termino Contrato (opcional)
            </label>
            <div className="w-full mt-2 flex flex-wrap">
              <div className="w-full md:pr-4 md:w-1/2">
                <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                  Desde
                </label>
                <input
                  {...register("fechaTerminoDesde")}
                  className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                  type="date"
                />
              </div>

              <div className="w-full md:pl-4 md:w-1/2">
                <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                  Hasta
                </label>
                <input
                  {...register("fechaTerminoHasta")}
                  className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                  type="date"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="w-full md:w-3/12 md:mr-3">
            <label className="dark:text-white text-hercules-light-font-secondary">
              Haber
            </label>
            <select
              {...register("haberId")}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option hidden value="0">
                Todos
              </option>
              {listaHaberes.map((haber, i) => (
                <option value={haber.id} key={i}>
                  {haber.nombre}
                </option>
              ))}
            </select>
          </div>

          <div className="w-full md:w-2/12 md:mx-3">
            <label className="dark:text-white text-hercules-light-font-secondary">
              Jornada
            </label>
            <select
              {...register("jornadaId")}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option value={"0"}>Todas</option>
              {jornadas?.map((jornada, i) => (
                <option value={jornada.id} key={i}>
                  {jornada.nombre}
                </option>
              ))}
            </select>
          </div>

          <div className="w-full md:w-3/12 md:mx-3">
            <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
              Sección
            </label>
            <select
              {...register("seccionId")}
              className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
            >
              <option value={"0"}>Todas</option>
              {secciones?.map((el) => (
                <>
                  <option value={el.id}>{el.nombre}</option>
                </>
              ))}
            </select>
          </div>

          <div className="w-full md:w-3/12 md:mx-3">
            <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
              Cargo
            </label>
            <select
              {...register("cargoId", validacionCampoObligatorio)}
              className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
            >
              <option value={"0"}>Todos</option>
              {cargos?.map((el) => (
                <>
                  <option value={el.id}>{el.nombre}</option>
                </>
              ))}
            </select>
            <span
              className={`text-red-500 dark:text-red-400 text-xs italic ${
                !errors.cargoId && "opacity-0 invisible"
              }`}
            >
              {errors.cargoId ? errors.cargoId.message : "hidden"}
            </span>
          </div>
        </div>

        <div className="w-full md:w-3/12 md:mt-3 mb-6">
          <button
            type="submit"
            className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
          >
            Buscar
          </button>
        </div>
      </form>

      {infoTabla.length > 0 && (
        <div className="h-full md:mr-12 rounded-lg mt-5 md:mt-0">
          <TablaActualizarAnexosContratos
            col={COLUMNS}
            tableData={infoTabla}
            isAnexosContrato={watch("tipoContratoAnexo") === "Anexo" ? true : false}
            filasSeleccionadas={filasSeleccionadasObjVacio}
            resetearFiltros={() => {
              setInfoTabla([])
              reset()
            }}
          />
        </div>
      )}
    </div>
  );
}
