import { axiosPrivate } from "../../../common/axiosPrivate";
import React, { useEffect, useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import ColumnFilter from "../../ColumnFilter";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ModalSpinner from "../../ModalSpinner";
import SimpleTable from "../../SimpleTable";
import RutColumnFilter from "../../RutColumnFilter";

export default function ModalVerCargasFamiliares({
  isVisible,
  onClose,
  modalData,
}) {
  // const history = useHistory();

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const periodoId = localStorage.getItem("periodoId");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const { refreshTable, setRefreshTable, screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const [editarMontoHaberVariable, setEditarMontoHaberVariable] = useState({
    estado: false,
    monto: "",
    contratoHaberId: "",
    anexoHaberId: "",
    indice: "",
  });

  const COLUMNS = [
    {
      Header: "N°",
      accessor: (d,i) => i + 1,
    },
    {
      Header: "Tipo Carga",
      accessor: (d) => {
        if (d.reintegro === true || d.retroactiva === true) {
          return "N/A";
        }
        if (d.tipoCarga === 0) {
          return "Normal";
        } else if (d.tipoCarga === 1) {
          return "Invalidez";
        } else if (d.tipoCarga === 2) {
          return "Maternal";
        }
      },
    },
    {
      Header: "Monto",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.monto)
    },
    {
      Header: "Retroactiva",
      accessor: (d) => (d.retroactiva ? "Si" : "No"),
    },
    {
      Header: "Reintegro",
      accessor: (d) => (d.reintegro ? "Si" : "No"),
    }
    // },
    // {
    //   Header: "RUT",
    //   accessor: "rut",
    //   Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
    //   Filter: RutColumnFilter,
    // },
    // {
    //   Header: "Nombres",
    //   accessor: (d) =>
    //     d.nombres + " " + d.apellidoPaterno + " " + d.apellidoMaterno,
    //   Filter: ColumnFilter,
    // },
    // {
    //   Header: "Cargas Totales",
    //   accessor: "numeroCargasTotales",
    //   Filter: ColumnFilter,
    // },
    // {
    //   Header: "Cargas Invalidez",
    //   accessor: "numeroCargasInvalidez",
    //   Filter: ColumnFilter,
    // },
    // {
    //   Header: "Asignación Familiar Mensual",
    //   accessor: (d) =>
    //     "$" + UtilsFunctions.integerFormatter(d.asignacionFamiliarTotalMensual),
    //   Filter: ColumnFilter,
    // },
    // {
    //   Header: "Tramo Asignación Familiar",
    //   accessor: "tramoAsignacionFamiliar",
    //   Filter: ColumnFilter,
    // },
    // {
    //   Header: "Cargas Familiar Maternal",
    //   accessor: (d) => (d.cargaFamiliarMaternal ? "Si" : "No"),
    //   Filter: ColumnFilter,
    // },
  ];

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null); // Para almacenar la data de la API

  useEffect(() => {
    if (isVisible) {
      const fetchData = async () => {
        // setLoading(true);
        try {
          const response = await axiosPrivate(
            `${api_service}/api/v1/trabajadores/cargas-familiares/detalle/${modalData.cargaFamiliarTrabajadorId}`,
            Authorization
          );
          setData(response.data);
          toast.success("Datos cargados correctamente");
        } catch (error) {
          console.error("Error al cargar los datos:", error);
          toast.error("Error al cargar los datos");
        } finally {
          // setLoading(false);
        }
      };

      fetchData();
    }
  }, [isVisible, modalData]); // Dependencias: se ejecuta cuando `isVisible` o `idCargaFamiliar` cambian
  
      async function botonEliminarRegistros() {
        toast.loading("Eliminando..");
        // setBotonGuardarHabilitado(false);
        try {
            await axiosPrivate.delete(
              `${api_service}/api/v1/trabajadores/cargas-familiares`,
              {
                data: [modalData.cargaFamiliarTrabajadorId],
                headers: {
                  Authorization: `Bearer ${tokenUser}`,
                  ClientId: `${token}`,
                },
              }
            );    

          setRefreshTable(!refreshTable);
          onClose();
          toast.dismiss();
          toast.success("Registro eliminados con éxito", estilosAlerta);
          // setBotonGuardarHabilitado(true);
        } catch (error) {
          onClose();
          toast.dismiss();
          toast.error(
            error.response.data.message
              ? error.response.data.message
              : "Error al eliminar registro"
          );
          // setBotonGuardarHabilitado(true);
        }
      }
    
  if (!isVisible) {
    return null;
  }

  return (
    <>
      <div className="primerDivModal">
        <div className="segundoDivModal max-w-6xl">
          {/*content*/}
          <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
            {/*header*/}
            <div className="divHeaderModal border-slate-200">
              <h3 className="titulo-Modal">Ver detalle Cargas Familiares</h3>
            </div>
            {/*body*/}
            <div
              className={`relative p-6 ${
                !loading ? "overflow-hidden" : "overflow-auto"
              } flex-auto dark:bg-hercules-dark-bg-200`}
            >
              {!loading ? (
                <div className="h-fix">
                  <ModalSpinner />
                </div>
              ) : (
                <>
                  <div className="md:flex md:flex-wrap">
                    <div className="w-full md:w-3/12 md:pr-3 md:mb-11 mb-9">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        RUT
                      </label>
                      <input
                        disabled
                        value={UtilsFunctions.rutFormatter(modalData.rut, {
                          dots: true,
                        })}
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>

                    <div className="w-full md:w-3/12 md:px-3 md:mb-11 mb-9">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        Nombres
                      </label>
                      <input
                        disabled
                        value={modalData.nombres}
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>

                    <div className="w-full md:w-3/12 md:px-3 md:mb-11 mb-9">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        Apellido Paterno
                      </label>
                      <input
                        disabled
                        value={modalData.apellidoPaterno}
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>

                    <div className="w-full md:w-3/12 md:pl-3 md:mb-11 mb-9">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        Apellido Materno
                      </label>
                      <input
                        disabled
                        value={modalData.apellidoMaterno}
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>
                  </div>

                  {modalData && (
                    <div className="h-full rounded-lg">
                      <div className="h-full w-full rounded-lg mb-9">
                        {/* <h3 className="mb-2 font-semibold dark:text-white text-hercules-light-font-secondary">
                              Descuentos Obligatorios
                            </h3> */}
                        <SimpleTable
                          col={COLUMNS}
                          tableData={data || []}
                          botonEditar={false}
                          hasPagination={false}
                          searchBar={false}
                          showFilters={false}
                          tableHeight="h-56"
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            {/*footer*/}
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  onClose();
                  setEditarMontoHaberVariable({
                    ...editarMontoHaberVariable,
                    estado: false,
                  });
                }}
              >
                Cerrar
              </button>
              <button
                onClick={() => {
                  botonEliminarRegistros();
                }}
                className="hover:scale-105 bg-red-500 text-white font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-in-out transition-all duration-400"
              >
                Eliminar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
