import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCentroCostos,
  getCentrosCostosByEmpresaId,
  listaPeriodosHastaActual,
} from "../../Redux/Actions";
import { useStateContext } from "../../contexts/ContextProvider";
import { ColumnFilter } from "../../components";
import ImprimirLiquidacionesTable from "../../components/Movimientos/ImprimirLiquidaciones/ImprimirLiquidacionesTable";
import UtilsFunctions from "../../utils/UtilsFunctions";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../common/axiosPrivate";
import { toast } from "react-hot-toast";
import { Link, useLocation } from "react-router-dom";
import RutColumnFilter from "../../components/RutColumnFilter";

export default function ImpresionLiquidaciones() {
  const {
    register,
    getValues,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      centroCostoId: -1,
      periodoAnyo: "",
    },
  });

  const { refreshTable, activeMenu, screenSize } = useStateContext();
  const dispatch = useDispatch();
  const [mesesData, setMesesData] = useState([]);
  const [infoPeriodos, setInfoPeriodos] = useState([]);
  const anyosData = UtilsFunctions.obtenerAnyosHastaActual();
  const [infoLiquidaciones, setInfoLiquidaciones] = useState([]);
  const infoCCosto = useSelector((state) => state.centrosCostoEmpresa);

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const empresaId = localStorage.getItem("empresaId");
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  async function getPeriodosCargados() {
    const infoMeses = await axiosPrivate(
      `${api_service}/api/v1/periodos/cargados`,
      Authorization
    );
    setInfoPeriodos(infoMeses.data);
  }

  const handleYearChange = (e) => {
    const year = parseInt(e);
    const yearData = infoPeriodos.find((item) => item.anyo === year);
    if (yearData) {
      const filteredMonths = yearData.periodos.filter(
        (mes) => mes.datosPrevisionalesCargados === true
      );
      const transformedMonths = filteredMonths.map((month) =>
        UtilsFunctions.integerToMonth(month.mes)
      );
      setMesesData(transformedMonths);
    } else {
      setMesesData([]);
    }
  };

  useEffect(() => {
    try {
      getPeriodosCargados();
      dispatch(listaPeriodosHastaActual());
      dispatch(getCentroCostos());
      dispatch(getCentrosCostosByEmpresaId(empresaId));
    } catch (error) {
      console.log(error);
    }
  }, [refreshTable, dispatch]);

  async function buscarLiquidacionesPorPeriodoCCosto() {
    const data = getValues();
    toast.dismiss();
    toast.loading("Cargando", estilosAlerta);
    try {
      const info = await axiosPrivate.get(
        `${api_service}/api/v1/periodos/mes/${data.numeroMes}/anyo/${data.periodoAnyo}`,
        Authorization
      );
      setValue("periodoId", info.data.id);
      const payload = {
        periodoId: info.data.id,
        centroCostoId: data.centroCostoId,
        empresaId: empresaId,
      };
      const responseLiquidaciones = await axiosPrivate.post(
        `${api_service}/api/v1/trabajadores/obtenerLiquidacionesPorPeriodoCentroCosto`,
        payload,
        Authorization
      );
      if (!responseLiquidaciones.data.length > 0) {
        setInfoLiquidaciones([]);
        toast.dismiss();
        toast.error(
          "Sin Liquidaciones en este Periodo y Centro de costo",
          estilosAlerta
        );
      } else {
        setInfoLiquidaciones(responseLiquidaciones.data);
        toast.dismiss();
        toast.success("Liquidaciones cargadas con éxito", estilosAlerta);
      }
    } catch (error) {
      console.log("error", error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al cargar Liquidaciones",
        estilosAlerta
      );
    }
  }

  const COLUMNS = [
    {
      Header: "RUT",
      accessor: "rutTrabajador",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Apellido paterno",
      accessor: "apellidoPaternoTrabajador",
      Filter: ColumnFilter,
    },
    {
      Header: "Apellido materno",
      accessor: "apellidoMaternoTrabajador",
      Filter: ColumnFilter,
    },
    {
      Header: "Nombre",
      accessor: "nombresTrabajador",
      Filter: ColumnFilter,
    },
    {
      Header: "Líquido a pagar",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.liquidoAPagar),
      Filter: ColumnFilter,
    },
  ];
  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Movimientos</div>
      <div className="titulo-Pagina">Impresión de liquidaciones</div>
      <form
        className="w-full pt-3 flex flex-wrap"
        onSubmit={handleSubmit(buscarLiquidacionesPorPeriodoCCosto)}
      >
        <div className="flex flex-wrap w-full md:w-fit">
          <div className="w-4/12 md:w-2/12 md:min-w-[100px] md:max-w-[200px] pr-3 mb-3">
            <label
              htmlFor="periodoId"
              className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
            >
              Periodo
            </label>
            <select
              {...register("periodoAnyo", {
                onChange: (e) => {
                  setValue("periodoMes", "");
                  handleYearChange(e.target.value);
                  setInfoLiquidaciones([]);
                },
                required: { value: true, message: "Obligatorio" },
              })}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option hidden value="">
                Año
              </option>
              {anyosData.map((anyo, i) => (
                <option value={anyo} key={i}>
                  {anyo}
                </option>
              ))}
            </select>
            <span
              className={`dark:text-red-400 text-red-600 text-xs ${
                !errors.periodoAnyo && "opacity-0 invisible"
              }`}
            >
              {errors.periodoAnyo ? errors.periodoAnyo.message : "hidden"}
            </span>
          </div>

          <div className="w-5/12 md:w-1/12 md:min-w-[130px] md:max-w-[200px] mt-6 md:mr-3 mb-3">
            <select
              {...register("periodoMes", {
                onChange: (e) => {
                  setValue("numeroMes", e.target.selectedIndex);
                  setInfoLiquidaciones([]);
                },
                required: { value: true, message: "Obligatorio" },
              })}
              disabled={watch("periodoAnyo") === "" ? true : false}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option hidden value="">
                Mes
              </option>
              {mesesData.map((mes, i) => (
                <option value={mes} key={i}>
                  {mes}
                </option>
              ))}
            </select>
            <span
              className={`dark:text-red-400 text-red-600 text-xs ${
                !errors.periodoMes && "opacity-0 invisible"
              }`}
            >
              {errors.periodoMes ? errors.periodoMes.message : "hidden"}
            </span>
          </div>
        </div>

        <div className="w-full md:w-72 md:px-3 mb-3 md:mb-0">
          <label class="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
            Centro de Costo
          </label>
          <select
            {...register("centroCostoId", {
              // required: "Debe seleccionar un Centro de Costo",
              onChange: (e) => {
                const centroCosto = infoCCosto[e.target.selectedIndex - 1];
                setValue("centroCosto", centroCosto);
                setInfoLiquidaciones([]);
              },
            })}
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option hidden value="">
              Seleccionar
            </option>
            <option value={"-1"}>Todos</option>
            {infoCCosto.map((ccosto, i) => (
              <option value={ccosto.id} key={i}>
                {ccosto.nombre}
              </option>
            ))}
          </select>
        </div>

        <div className="w-full md:w-36 md:pl-3 mt-3 mb-6 md:mb-0">
          <button
            type="submit"
            className="mt-3 hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-in-out transition-all duration-400"
          >
            Buscar
          </button>
        </div>

        <div className="mt-3 md:mt-6">
          <Link to="/movimientos/impresion-liquidaciones-individuales">
            <button className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-in-out transition-all duration-400">
              Imprimir Por Trabajador
            </button>
          </Link>
        </div>
      </form>

      {infoLiquidaciones.length > 0 && (
        <div className="h-full mt-3 md:mr-12 rounded-lg">
          <ImprimirLiquidacionesTable
            col={COLUMNS}
            tableData={infoLiquidaciones}
            periodoSeleccionadoId={getValues("periodoId")}
            periodoMesSeleccionado={getValues("periodoMes")}
            periodoAnyoSeleccionado={getValues("periodoAnyo")}
            tipo="Periodo"
          />
        </div>
      )}
    </div>
  );
}
