import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useStateContext } from "../../contexts/ContextProvider";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { axiosPrivate } from "../../common/axiosPrivate";
import ImprimirPlantillasTable from "../../components/Herramientas/ImprimirPlantillas/ImprimirPlantillasTable";
import { ColumnFilter } from "../../components";
import UtilsFunctions from "../../utils/UtilsFunctions";
import RutColumnFilter from "../../components/RutColumnFilter";
import {
  getCentrosCostosByEmpresaId,
  limpiarInfoMenuEdicion,
  obtenerPlantillasConfiguradasEmpresa,
  obtenerPlantillasEmpresa,
} from "../../Redux/Actions";
import { dateBetweenFilterFn, DateRangeColumnFilter } from "../../components/Filters";

export default function ImprimirPlantillas() {
  const {
    register,
    getValues,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      entidadAsignada: "",
    },
  });

  const { refreshTable, setRefreshTable, activeMenu, screenSize } =
    useStateContext();

  const plantillaId = watch("plantillaId");
  const plantillaNombre = watch("plantillaNombre");
  const entidadAsignada = watch("entidadAsignada");
  const preguntarFechaDocumento = watch("preguntarFechaDocumento");
  const centroCostoId = watch("centroCostoId");
  const [infoPlantillas, setInfoPlantillas] = useState([]);

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const dispatch = useDispatch();
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const empresaId = localStorage.getItem("empresaId");
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const validacionCampoObligatorio = {
    required: { value: true, message: "Obligatorio" },
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(obtenerPlantillasConfiguradasEmpresa(empresaId)),
        dispatch(getCentrosCostosByEmpresaId(empresaId)),
      ]);
    };
    fetchData().catch((error) => {
      console.log(error.response.data.message);
    });
  }, [dispatch, refreshTable]);

  const infoTablaPlantillasConfiguradasEmpresa = useSelector(
    (state) => state.infoPlantillasConfiguradasEmpresa
  );

  const infoCCosto = useSelector((state) => state.centrosCostoEmpresa);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("infoPlantillasConfiguradasEmpresa"));
    };
  }, []);

  async function onSubmit() {
    const data = getValues();
    toast.loading("Cargando", estilosAlerta);
    try {
      const requestBody = {
        empresaId: empresaId,
        entidad: entidadAsignada,
        centroCostoId: centroCostoId,
        fechaDesde:
          entidadAsignada === "Contrato"
            ? data.fechaIngresoLegal
            : entidadAsignada === "AnexoContrato" ? data.fechaAnexo
            : entidadAsignada === "Finiquito" && data.fechaDesdeFiniquito,
        fechaHasta: data.fechaTermino,
      };

      const response = await axiosPrivate.post(
        `${api_service}/api/v1/plantillas/contratos-anexos`,
        requestBody,
        Authorization
      );
      console.log("response", response);
      if (!response.data.length > 0) {
        toast.dismiss();
        toast.error(
          "Sin información para los datos seleccionados",
          estilosAlerta
        );
        setInfoPlantillas([]);
      } else {
        setInfoPlantillas(response.data);
        setRefreshTable(!refreshTable);
        toast.dismiss();
        toast.success("Información cargada con éxito", estilosAlerta);
      }
    } catch (error) {
      console.log("error", error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al cargar información",
        estilosAlerta
      );
    }
  }

  const COLUMNS_CONTRATOS = [
    {
      Header: "RUT",
      accessor: "rutTrabajador",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Nombre",
      accessor: "nombreTrabajador",
      Filter: ColumnFilter,
    },
    {
      Header: "Centro Costo",
      accessor: "nombreCentroCosto",
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha Ingreso Legal",
      accessor: "fechaIngresoLegal",
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha Termino Contrato",
      accessor: (d) =>
        d.fechaTerminoContrato ? d.fechaTerminoContrato : "Indefinido",
      Filter: ColumnFilter,
    },
  ];

  const COLUMNS_ANEXOS = [
    {
      Header: "RUT",
      accessor: "rutTrabajador",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Nombre",
      accessor: "nombreTrabajador",
      Filter: ColumnFilter,
    },
    {
      Header: "Centro Costo",
      accessor: "nombreCentroCosto",
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha Anexo",
      accessor: "fechaAnexo",
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha Termino Contrato",
      accessor: "fechaTerminoAnexo",
      Cell: ({ value }) => (value ? value : "Indefinido"),
      Filter: ColumnFilter,
    },
  ];

  const COLUMNS_FINIQUITOS = [
    {
      Header: "RUT",
      accessor: "rutTrabajador",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Nombre",
      accessor: "nombreTrabajador",
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha Finiquito",
      accessor: "fechaFiniquito",
      Filter: ColumnFilter,
    },
  ];

  console.log(
    "infoTablaPlantillasConfiguradasEmpresa",
    infoTablaPlantillasConfiguradasEmpresa
  );

  return (
    <>
      <div
        className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
          activeMenu && "md:w-[calc(100vw_-_23rem)]"
        }`}
      >
        <div className="titulo-Menu">Herramientas</div>
        <div className="titulo-Pagina">Impresion de Plantillas</div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="pt-3 flex flex-wrap">
            <div className="w-full md:w-3/12 mt-4 mb-6 pr-3">
              <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                Plantilla
              </label>
              <select
                {...register("plantillaId", {
                  onChange: (e) => {
                    setInfoPlantillas([]);
                    const plantilla =
                      infoTablaPlantillasConfiguradasEmpresa[
                        e.target.selectedIndex - 1
                      ];
                    setValue("entidadAsignada", plantilla.entidadAsignada);
                    setValue("plantillaNombre", plantilla.nombre);
                    setValue(
                      "preguntarFechaDocumento",
                      plantilla.preguntarFechaDocumento
                    );
                    setValue("fechaIngresoLegal", "");
                    setValue("fechaAnexo", "");
                    setValue("fechaTermino", "");
                  },
                })}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option hidden value="">
                  Seleccionar Plantilla
                </option>
                {infoTablaPlantillasConfiguradasEmpresa.map((plantilla, i) => (
                  <option value={plantilla.id} key={i}>
                    {plantilla.nombre}
                  </option>
                ))}
              </select>
            </div>

            <div className="w-full md:w-72 md:mt-4 md:px-3 mb-3 md:mb-0">
              <label class="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                Centro de Costo
              </label>
              <select
                {...register("centroCostoId", {
                  onChange: (e) => {
                    const centroCosto = infoCCosto[e.target.selectedIndex - 1];
                    setValue("centroCosto", centroCosto);
                  },
                })}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option value={"0"}>Todos</option>
                {infoCCosto.map((ccosto, i) => (
                  <option value={ccosto.id} key={i}>
                    {ccosto.nombre}
                  </option>
                ))}
              </select>
            </div>

            {entidadAsignada === "" || !entidadAsignada ? null : (
              <>
                {entidadAsignada === "Contrato" ? (
                  <div className="w-full md:w-2/12 px-3 mt-4 mb-6">
                    <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                      Desde
                    </label>
                    <input
                      {...register(
                        "fechaIngresoLegal"
                        // validacionCampoObligatorio
                      )}
                      className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                      type="date"
                    />
                    <span
                      className={`dark:text-red-400 text-red-600 text-xs ${
                        !errors.fechaIngresoLegal && "opacity-0 invisible"
                      }`}
                    >
                      {errors.fechaIngresoLegal
                        ? errors.fechaIngresoLegal.message
                        : "hidden"}
                    </span>
                  </div>
                ) : 
                  entidadAsignada === "AnexoContrato" ? (
                    <div className="w-full md:w-2/12 px-3 mt-4 mb-6">
                      <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                        Desde
                      </label>
                      <input
                        {...register("fechaAnexo", validacionCampoObligatorio)}
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="date"
                      />
                      <span
                        className={`dark:text-red-400 text-red-600 text-xs ${
                          !errors.fechaAnexo && "opacity-0 invisible"
                        }`}
                      >
                        {errors.fechaAnexo
                          ? errors.fechaAnexo.message
                          : "hidden"}
                      </span>
                    </div>
                  ) : (
                  entidadAsignada === "Finiquito" && (
                    <div className="w-full md:w-2/12 px-3 mt-4 mb-6">
                      <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                        Desde
                      </label>
                      <input
                        {...register("fechaDesdeFiniquito", validacionCampoObligatorio)}
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="date"
                      />
                      <span
                        className={`dark:text-red-400 text-red-600 text-xs ${
                          !errors.fechaDesdeFiniquito && "opacity-0 invisible"
                        }`}
                      >
                        {errors.fechaDesdeFiniquito
                          ? errors.fechaDesdeFiniquito.message
                          : "hidden"}
                      </span>
                    </div>
                  )
                )}

                <div className="w-full md:w-2/12 px-3 mt-4 mb-6">
                  <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                    Hasta
                  </label>
                  <input
                    {...register(
                      "fechaTermino"
                      // validacionCampoObligatorio
                    )}
                    className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                    type="date"
                  />
                  <span
                    className={`dark:text-red-400 text-red-600 text-xs ${
                      !errors.fechaTermino && "opacity-0 invisible"
                    }`}
                  >
                    {errors.fechaTermino
                      ? errors.fechaTermino.message
                      : "hidden"}
                  </span>
                </div>

                <div className="w-4/12 md:w-[110px] md:mt-[39px] md:ml-3 mb-6 md:mb-0">
                  <button
                    type="submit"
                    className="w-full hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-in-out transition-all duration-400"
                  >
                    Buscar
                  </button>
                </div>
              </>
            )}
          </div>
        </form>

        {infoPlantillas.length > 0 && (
          <div className="h-full md:mr-12 rounded-lg">
            <ImprimirPlantillasTable
              col={
                entidadAsignada === "Contrato" ? COLUMNS_CONTRATOS
                  : entidadAsignada === "AnexoContrato" ? COLUMNS_ANEXOS 
                  : entidadAsignada === "Finiquito" && COLUMNS_FINIQUITOS
              }
              tableData={infoPlantillas}
              plantillaId={plantillaId}
              plantillaNombre={plantillaNombre}
              entidadAsignada={entidadAsignada}
              preguntarFechaDocumento={preguntarFechaDocumento}
              // periodoMesSeleccionado={getValues("periodoMes")}
              // periodoAnyoSeleccionado={getValues("periodoAnyo")}
              // tipo="Periodo"
            />
          </div>
        )}
      </div>
    </>
  );
}
